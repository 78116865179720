<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <div class="container">
      <el-card class="box-card">
        <template #header>
          <div class="grade">
            <el-image :src="grade.thumb"></el-image>
            <div class="grade-info">
              <div class="info-title">{{ grade.title }}</div>
              <div class="info-sub">
                <span>已看完/总节数：{{ completeSection }} / {{ historyList.length }}</span>
                <span>总观看度：{{ percent }}%</span>
              </div>
            </div>
          </div>
        </template>
        <div class="sections">
          <el-row :gutter="20">
            <el-col :span="12" v-for="item in historyList" :key="item.id">
              <div class="sections-item" @click="goVideoPlayer(item)">
                <div class="item-title">
                  <el-tooltip content="点击继续观看" placement="top-start" effect="light">
                    <span>{{ item.title }}</span>
                  </el-tooltip>
                </div>
                <el-tooltip content="当前时间为真实播放时间，拖动、跳过的时间不计入其中" placement="bottom-end" effect="light">
                  <el-progress :text-inside="true" :stroke-width="20" :percentage="item.history ? item.history.percent * 100 : 0">
                    <span class="item-progress">{{ item.timeLine }}</span>
                  </el-progress>
                </el-tooltip>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import { getCourseHistory } from "@/api/user"
import { setLocalStorage, getSessionStorage } from '@/utils/auth'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  data() {
    return {
      // 当前班级信息
      grade: {},
      // 播放记录
      historyList: [],
      // 整个班级的观看百分比
      percent: 0,
      // 已看章节数
      completeSection: 0,
    }
  },
  props: ['gradeId'],
  created() {
    this.grade = getSessionStorage('gradeProgressCurrentGrade')
    // 根据班级id，查询到所有的观看记录
    getCourseHistory({grade_id: this.gradeId}).then(res => {
      // 处理一下历史记录
      this.historyList = res.data.list.map(item => {
        // 已看时长 / 总时长
        let watched = '00:00'
        if (item.history) {
          let m = parseInt(item.history.looked / 60)
          let s = Math.floor(item.history.looked % 60)
          watched = (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s)
        }
        item.timeLine = watched + ' / ' + item.duration.slice(2)
        return item
      })
      // 拿到服务器计算好的整个班级的百分比
      this.percent = (res.data.percent * 100).toFixed(2)
      // 找出已经看完的章节数量，只要百分比到95以上就算
      this.completeSection = res.data.list.filter(item => {
        return item.history != null && item.history.percent > 95
      }).length
    })

  },
  methods: {
    goVideoPlayer(section) {
      setLocalStorage('sectionHistory', section.history)
      this.$router.push('/courseplayer/' + this.grade.comboId + '/' + this.grade.id + '/' + section.id)
    }

  },
}
</script>

<style lang="scss" scoped>
.box-card {
  padding: 20px;
  margin: 20px 0px;
  cursor: default;
}
.grade {
  padding-bottom: 10px;
  display: flex;

  .el-image {
    width: 180px;
    margin-right: 15px;
  }
  .grade-info {

    .info-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .info-sub {
      color: #666;
      font-size: 14px;

      span {
        margin-right: 30px;
      }
    }
  }
}
.sections {
  margin: 10px 0;

  .sections-item {
    margin-bottom: 15px;

    .item-title {
      font-size: 16px;
      margin-bottom: 3px;
      cursor: pointer;
    }
    .item-progress {
      font-size: 12px;
    }
  }
  .sections-item:hover .item-title {
    color: $theme-color;
  }
}
</style>

<style lang="scss">
// 控制进度条的颜色是主题色
.sections-item .el-progress-bar__inner {
  background-color: $theme-color;
}
// 突出进度条边框
.sections-item:hover .el-progress-bar__outer {
  box-sizing: border-box;
  border: 1px solid $theme-color;
}
</style>
